/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import history from 'Util/History';

/** @namespace Satisfly/Component/CategoryItemsCount/Component */
export class CategoryItemsCountComponent extends PureComponent {
    static propTypes = {
        totalItems: PropTypes.number.isRequired,
        isMatchingListFilter: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    static defaultProps = {
        isMatchingListFilter: false,
        isLoading: false,
    };

    render() {
        const {
            totalItems,
            isMatchingListFilter,
            isLoading,
        } = this.props;

        const {
            location: {
                pathname,
            },
        } = history;

        const isSearchPage = pathname.includes('/search/');

        return (
            <p
              block="CategoryPage"
              elem="ItemsCount"
              mods={ {
                  isSearchPage,
                  isNoResults: totalItems === 0,
              } }
            >
                <TextPlaceholder
                  content={ (!isMatchingListFilter && isLoading
                      ? __('Products are loading...')
                      : (
                          <>
                        { __('Number of products:') }
                        <span>{ totalItems }</span>
                          </>
                      )

                  ) }
                />
            </p>
        );
    }
}

export default CategoryItemsCountComponent;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CategoryDetailsComponent as SourceCategoryDetails } from 'SourceComponent/CategoryDetails/CategoryDetails.component';

import './CategoryDetails.style';

/**
 * Category details
 * @class CategoryDetails
 * @namespace Satisfly/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetails {
    render() {
        const {
            category: { product_count },
        } = this.props;

        const isNoProducts = product_count === 0;

        return (
            <article block="CategoryDetails" mods={ { isNoProducts } }>
                <div block="CategoryDetails" elem="Description" mods={ { isNoProducts } }>
                    { this.renderCategoryName() }
                    { this.renderCategoryDescription() }
                </div>
                { this.renderCategoryImage() }
            </article>
        );
    }
}

export default CategoryDetailsComponent;
